import React from "react";
import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonTabs,
  SkeletonThumbnail,
  Text,
} from "@shopify/polaris";

export function CommonSkeletonPage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={1} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

const PricePage = ({ elements, isPremium }) => {
  return (
    <>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "16px",
                height: process.env.ISFREEAPP == 1 ? "100%" : "43.5rem",
              }}
            >
              <Card>
                <div style={{ width: "18rem" }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ width: !isPremium ? "3rem" : "5rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {!isPremium ? (
                        <div className="pricing-SkeletonThumbnail">
                          <SkeletonThumbnail size="extraSmall" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    <div className="pricing-SkeletonBodyText">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ width: "3rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {isPremium ? (
                        <div style={{ width: "8rem" }}>
                          <SkeletonBodyText lines={1} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    {isPremium ? (
                      <div
                        className="pricing-SkeletonBodyText premium-page--skeleton"
                        style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                      >
                        <div className="premium-skeleton">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    {isPremium ? (
                      <div className="premium-skeleton--page">
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <SkeletonBodyText lines={1} />
                    <br />
                    <div>{elements}</div>
                    <br />
                    {process.env.ISFREEAPP == 1 ? (
                      <div className="pricing-SkeletonDisplayText">
                        <SkeletonDisplayText size="medium" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </>
  );
};

export function PricingSkeleton() {
  const elements = [];
  for (let i = 0; i < 9; i++) {
    elements.push(
      <div key={`div-${i}`} style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <div className="pricing-SkeletonThumbnail">
          <SkeletonThumbnail size="extraSmall" />
        </div>
        <div style={{ width: "10rem" }}>
          <SkeletonBodyText lines={1} />
        </div>
      </div>
    );
    if (i !== 8) {
      elements.push(<br key={`br-${i}`} />);
    }
  }
  let isPremium = true;
  return (
    <>
      <SkeletonPage primaryAction>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap" }}>
          <PricePage elements={elements} />
          {process.env.ISFREEAPP == 0 ? <PricePage elements={elements} isPremium={isPremium} /> : ""}
        </div>
      </SkeletonPage>
    </>
  );
}

export function CountryBlockerSkeleton() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <div className="countrybloker-skeleton--inlinegrid">
            <InlineGrid columns={["twoThirds", "oneThird"]} gap="400">
              <Card>
                <BlockStack gap={200}>
                  <div className="country-blocker--bodyText" style={{ marginBottom: "0.5rem" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                        paddingTop: "1rem",
                        // paddingLeft: "3rem",
                        // paddingRight: "3rem",
                        // overflow: "hidden",
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                        <div className="country-blocker--display">
                          <div
                            className="SkeletonDisplayText-first"
                            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                          >
                            <SkeletonBodyText lines={1} />
                            {/* <SkeletonDisplayText size="small" /> */}
                          </div>
                          <div className="SkeletonDisplayText-second">
                            <SkeletonDisplayText size="large" />
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                        <div className="country-blocker--display">
                          <div
                            className="SkeletonDisplayText-first"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <SkeletonBodyText lines={1} />
                          </div>
                          <div className="SkeletonDisplayText-second">
                            <SkeletonDisplayText size="small" />
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                          <div className="country-blocker--display">
                            <SkeletonBodyText lines={1} />
                            <SkeletonDisplayText size="large" />
                          </div>
                          <div className="country-blocker--display">
                            <SkeletonBodyText lines={1} />
                            <SkeletonDisplayText size="large" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </BlockStack>
              </Card>

              {/* <div className="coutry-blocker--saveBtn">
                <SkeletonDisplayText size="small" />
              </div> */}
              <Card>
                <div className="country-blocker--vpnbodyText ">
                  <SkeletonBodyText lines={1} />
                  <div className="country-preview">
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1rem" }}>
                      <div className="country-blocker--title">
                        <SkeletonDisplayText size="small" />
                      </div>
                      <div className="country-blocker--message">
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </InlineGrid>
          </div>
        </Layout.Section>
      </Layout>
      {/* <div className="country-blocker--vpnbodyText">
        <SkeletonBodyText lines={1} />
      </div> */}
      <br />
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            // marginTop: "1rem",
            overflow: "hidden",
          }}
        >
          <div className="country-blocker--vpn">
            <SkeletonBodyText lines={1} />
          </div>
          <div className="country-blocker--activate">
            <SkeletonDisplayText size="small" />
          </div>
        </div>
      </Card>
    </SkeletonPage>
  );
}

const DashboardOption = () => {
  const options = [];
  for (let i = 0; i < 6; i++) {
    options.push(
      <Card key={i}>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center", padding: "0.5rem 0" }}>
          <div className="dashboard-switch">
            <SkeletonThumbnail size="extraSmall" />
          </div>
          <div className="dashboard-text">
            <div className="dashbpard-text--title">
              <SkeletonBodyText lines={1} />
            </div>
            <div className="dashbpard-text--detail">
              <SkeletonBodyText lines={1} />
            </div>
          </div>
        </div>
      </Card>
    );
  }
  return options;
};

const MobileOption = () => {
  const options = [];
  for (let i = 0; i < 3; i++) {
    options.push(
      <Card key={i}>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center", padding: "0.5rem 0" }}>
          <div className="dashboard-switch">
            <SkeletonThumbnail size="extraSmall" />
          </div>
          <div className="dashboard-text">
            <div className="dashbpard-text--title">
              <SkeletonBodyText lines={1} />
            </div>
            <div className="dashbpard-text--detail">
              <SkeletonBodyText lines={1} />
            </div>
          </div>
        </div>
      </Card>
    );
  }
  return options;
};

export function DashboardSkeleton({ isEnabled }) {
  const hideReview = localStorage.getItem("Review");
  const hideOtherApps = localStorage.getItem("OtherApps");
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {!isEnabled && isEnabled != undefined && (
              <Card>
                <SkeletonBodyText lines={2} />
                <br />
                <SkeletonDisplayText size="small" />
              </Card>
            )}
            <div className="dashboard-blockstack">
              <BlockStack gap="500">
                <InlineGrid columns={2} gap={300} alignItems="start">
                  <Card>
                    <div className="dashboard-settings">
                      <SkeletonDisplayText size="small" />
                      <div style={{ marginTop: "1rem" }}></div>
                      <BlockStack gap={300}>
                        <DashboardOption />
                      </BlockStack>
                    </div>
                  </Card>
                  <Card>
                    <div className="dashboard-settings">
                      <SkeletonDisplayText size="small" />
                      <div style={{ marginTop: "1rem" }}></div>
                      <BlockStack gap={300}>
                        <MobileOption />
                      </BlockStack>
                    </div>
                  </Card>
                </InlineGrid>
                {/* <InlineGrid columns={2} gap={300}>
                  <Card>
                    <BlockStack gap={300} inlineAlign="center">
                      <div className="prolinkpage-icon--skeleton">
                        <SkeletonThumbnail size="extraSmall" />
                      </div>
                      <div className="prolinkpage-skelbodytext">
                        <SkeletonBodyText lines={1} />
                      </div>
                    </BlockStack>
                  </Card>
                  <Card>
                    <BlockStack gap={300} inlineAlign="center">
                      <div className="prolinkpage-icon--skeleton">
                        <SkeletonThumbnail size="extraSmall" />
                      </div>
                      <div className="prolinkpage-skelbodytext">
                        <SkeletonBodyText lines={1} />
                      </div>
                    </BlockStack>
                  </Card>
                </InlineGrid> */}
                <Card>
                  <div className="dashboard-show--alert">
                    <SkeletonBodyText lines={1} />
                    <SkeletonDisplayText size="small" />
                  </div>
                </Card>
                {!hideReview && (
                  <Card>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <div className="hideReview--skeleBodyOne">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <div className="hideReview--skeleBodySecond">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ width: "8rem", marginTop: "1rem" }}>
                          <SkeletonDisplayText />
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ marginRight: "4rem" }}>
                          <SkeletonThumbnail size="large" />
                        </div>
                        <div className="hideReview--remove">
                          <SkeletonBodyText lines={1} />
                        </div>
                      </div>
                    </div>
                  </Card>
                )}

                {!hideOtherApps && (
                  <Card>
                    <div className="protector-other-apps">
                      <InlineStack align="space-between">
                        <div className="protector--other-apps--title">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <div className="protector-discard">
                          <SkeletonBodyText lines={1} />
                        </div>
                      </InlineStack>
                    </div>
                    <br />
                    <div className="otherApp">
                      <Card>
                        <InlineStack wrap={false} gap={500} align="start">
                          <div style={{ display: "flex", gap: "0.5rem" }}>
                            <SkeletonThumbnail size="large" />
                            <div className="other-app-content">
                              <div className="other-app--name">
                                <SkeletonBodyText lines={2} />
                              </div>
                              <div className="other-app--text">
                                <div className="other-app-text--one">
                                  <SkeletonBodyText lines={1} />
                                </div>
                                <div className="other-app-text--two">
                                  <SkeletonBodyText lines={1} />
                                </div>
                              </div>
                              <SkeletonDisplayText size="small" />
                            </div>
                          </div>
                        </InlineStack>
                      </Card>
                      <Card>
                        <InlineStack wrap={false} gap={500} align="start">
                          <div style={{ display: "flex", gap: "0.5rem" }}>
                            <SkeletonThumbnail size="large" />
                            <div className="other-app-content">
                              <div className="other-app--name">
                                <SkeletonBodyText lines={2} />
                              </div>
                              <div className="other-app--text">
                                <div className="other-app-text--one">
                                  <SkeletonBodyText lines={1} />
                                </div>
                                <div className="other-app-text--two">
                                  <SkeletonBodyText lines={1} />
                                </div>
                              </div>
                              <SkeletonDisplayText size="small" />
                            </div>
                          </div>
                        </InlineStack>
                      </Card>
                    </div>
                    <br />
                    <div className="protector-show-more--apps">
                      <SkeletonDisplayText size="small" />
                    </div>
                  </Card>
                )}
              </BlockStack>
            </div>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export function LeagalLogSkeleton() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={500}>
              <div className="leagallog-title">
                <SkeletonBodyText lines={1} />
              </div>
              <Card>
                <BlockStack gap={500}>
                  <div className="leagallog-heading">
                    <SkeletonDisplayText size="small" />
                  </div>
                  <SkeletonBodyText lines={2} />
                  <div className="leagallog-para">
                    <SkeletonBodyText lines={1} />
                  </div>
                </BlockStack>
              </Card>
              <div className="leagallog-btn">
                <SkeletonDisplayText size="small" />
              </div>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export function AdminLinkSkeleton() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap={500}>
            <div className="adminlink-banner--skeleton">
              <Card>
                <div className="adminlink-banner">
                  <SkeletonThumbnail size="extraSmall" />
                  <SkeletonBodyText lines={1} />
                </div>
              </Card>
            </div>
            <div className="adminlink-skeleton">
              <Card>
                <div className="adminlink-generate">
                  <SkeletonBodyText lines={1} />
                  <SkeletonDisplayText size="small" />
                </div>
              </Card>
            </div>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
