import React, { Suspense, lazy, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { initReactI18next } from "react-i18next";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import { Loading } from "@shopify/polaris";
import i18n from "i18next";
import { onCLS, onFID, onLCP } from "web-vitals";
import { AppNavigationMenu } from "@/Components/Common/NavigationMenu";
import { logger } from "@/Services/Logger/Index";
import { adminEnvCheck, checkPartenerAcc, isAdmin, setLocalStorageItem } from "@/Utils/Index";
import NoAccessStoreDesign from "./Components/Common/NoAccessStoreDesign";
import { ProfileContext } from "./Context/ProfileContext";

const FallbackUIComponent = lazy(() => import("@/Components/Common/FallbackUIComponent"));
const Support = lazy(() => import("@/Components/Common/Support"));
// Import your components using lazy
const ReviewPopup = lazy(() => import("@/Components/Common/ReviewPopup"));
const BackendTesting = lazy(() => import("@/Pages/BackendTesting/BackendTesting"));
const ExitFrame = lazy(() => import("@/Pages/ExitFrame/ExitFrame"));
const FormPreview = lazy(() => import("@/Pages/FormPreview/FormPreview"));
const Index = lazy(() => import("@/Pages/Index/Index"));
const CountryBlocker = lazy(() => import("@/Pages/CountryBlocker/CountryBlocker"));
const AdminLink = lazy(() => import("@/Pages/AdminLink/AdminLink"));
const LeagalLog = lazy(() => import("@/Pages/LeagalLog/LeagalLog"));
const NotFound = lazy(() => import("@/Pages/NotFound/NotFound"));
const Other = lazy(() => import("@/Pages/Other/Other"));
const Pricing = lazy(() => import("@/Pages/Pricing/Pricing"));
const Faqs = lazy(() => import("@/Pages/Faqs/Faqs"));
const Settings = lazy(() => import("@/Pages/Settings/Settings"));
const OnBoarding = lazy(() => import("@/Pages/OnBoarding/OnBoarding"));
const Feedback = lazy(() => import("@/Pages/Feedback/Feedback"));
const Review = lazy(() => import("@/Pages/Review/Review"));

const Routes = () => {
  const { profileData } = useContext(ProfileContext);
  const [dataSent, setDataSent] = useState(false);
  const [appLanguage, setAppLanguage] = useState();

  useEffect(() => {
    if (profileData && !adminEnvCheck(profileData) && process.env.HOTJAR_TRACKING_ID) {
      Hotjar.init(process.env.HOTJAR_TRACKING_ID, process.env.HOTJAR_VERSION);
    }
  }, [profileData]);

  const sendToGoogleAnalytics = (data) => {
    const { name, delta, id, value, rating } = data;
    // console.log(name, delta, id, value, rating);
    if (process.env.ENV == "prod") {
      ReactGA.event(name, {
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta,
        rating: rating,
        isAdmin: Boolean(isAdmin()),
      });
    }
  };

  useEffect(() => {
    const initializeI18n = async () => {
      try {
        await i18n.use(initReactI18next).init();

        let languageToLoad = "en"; // Default to English
        if (!isAdmin() && profileData?.appLanguage) {
          languageToLoad = profileData.appLanguage;
        }
        const languageResource = await import(`@/LanguageJS/${languageToLoad}.js`);
        i18n.addResourceBundle(languageToLoad, "translation", languageResource.default);
        await i18n.changeLanguage(languageToLoad);
        setLocalStorageItem("appLanguage", languageToLoad);
        setAppLanguage(i18n.language);
      } catch (error) {
        logger.error(error);
      }
    };

    initializeI18n();
  }, [profileData?.appLanguage, i18n]);

  useEffect(() => {
    if (!dataSent) {
      onCLS((data) => sendToGoogleAnalytics(data, profileData));
      onLCP((data) => sendToGoogleAnalytics(data, profileData));
      onFID((data) => sendToGoogleAnalytics(data, profileData));
      setDataSent(true);
    }
  }, []);

  var simulateMouseEvent = function (element, eventName, coordX, coordY) {
    element.dispatchEvent(
      new MouseEvent(eventName, {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: coordX,
        clientY: coordY,
        button: 0,
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const theButton = document.getElementById("component");
      if (theButton) {
        var box = theButton.getBoundingClientRect(),
          coordX = box.left + (box.right - box.left) / 2,
          coordY = box.top + (box.bottom - box.top) / 2;
        simulateMouseEvent(theButton, "click", coordX, coordY);
      }
    }, 1000);
  }, []);

  if (profileData && profileData.error) {
    return <FallbackUIComponent />;
  }

  if (checkPartenerAcc(profileData)) {
    return <NoAccessStoreDesign />;
  }

  return (
    <React.Fragment>
      {!profileData && <div className="loading-overlay"></div>}
      <Suspense fallback={<Loading />}>
        <ReviewPopup />
        <Support />
        <AppNavigationMenu />
        {/* {profileData && !profileData.isOnBoardingDone && <OnBoarding />} */}
        <div id="component"></div>
        <ReactRouterRoutes>
          <Route path="/" element={<Index />} />
          <Route path="/countryblocker" element={<CountryBlocker />} />
          <Route path="/unblockip" element={<AdminLink />} />
          <Route path="/leagallog" element={<LeagalLog />} />
          <Route path="/backendTesting" element={<BackendTesting />} />
          <Route path="/exitframe" element={<ExitFrame />} />
          <Route path="/exitframe/:shop" element={<ExitFrame />} />
          <Route path="/pricing" element={<Pricing hasBillingButton={true} />} />
          <Route path="/other" element={<Other />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/form-preview" element={<FormPreview />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/review" element={<Review />} />
          <Route path="*" element={<NotFound />} />
        </ReactRouterRoutes>
      </Suspense>
    </React.Fragment>
  );
};

export default Routes;
