import { t } from "i18next";

export const getNavigationLinks = () => [
  // {
  //   label: t("dashboard.Content Protection"),
  //   destination: "/",
  // },
  {
    label: t("countryblocker.Country Blocker"),
    destination: "/countryblocker",
  },
  {
    label: t("Admin Link"),
    destination: "/unblockip",
  },
  {
    label: t("leagallog.Leagal Log"),
    destination: "/leagallog",
  },
  {
    label: t("pricing.Price"),
    destination: "/pricing",
  },
  {
    label: t("faqs.Faqs"),
    destination: "/faqs",
  },
  {
    label: t("dashboard.Profile"),
    destination: "/settings",
  },
  // {
  //   label: t("mocks.navigation.Other"),
  //   destination: "/Other",
  // },
  // {
  //   label: t("mocks.navigation.Feedback"),
  //   destination: "/feedback",
  // },
];
